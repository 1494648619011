import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import fragment from './shader/fragment.glsl'
import fragment1 from './shader/fragment1.glsl'
import vertex from './shader/vertex.glsl'
import vertex1 from './shader/vertex1.glsl'

import fragment2 from './shader/goldenSphereFragment.glsl'
import vertex2 from './shader/goldenSphereVertex.glsl'

import fragment3 from './shader/silverSphereFragment.glsl'
import vertex3 from './shader/silverSphereVertex.glsl'

import matcap from '../static/textures/matcaps/15.png'
import matcap2 from '../static/textures/matcaps/19.png'



import GUI from 'lil-gui'
import gsap from 'gsap'
import mixitup from './lib/mixitup.min.js'


import {DotScreenShader} from './CustomShader'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'


export default class Sketch {
  constructor(options) {
    this.scene = new THREE.Scene();

    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0xeeeeee, 1); 
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );

    // var frustumSize = 10;
    // var aspect = window.innerWidth / window.innerHeight;
    // this.camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000 );
    this.camera.position.set(0, 0, 1.3)
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.time = 0;

    this.isPlaying = true

    this.mouse = 0
  
    
    this.addObjects()
    this.mouseEvent()
    this.initPost()
    this.resize()
    this.render()
    this.setupResize()
    this.setModel()
    this.settings()
  }

  mouseEvent()
  {
            // // Mouse
            // document.addEventListener('mousemove', animateParticles)

            // let mouseX = 0
            // let mouseY = 0
        
            // function animateParticles(event) {
            //   mouseY = event.clientY
            //   mouseX = event.clientX
            // }
    this.mouseX = 0
    this.mouseY = 0 
            
    document.addEventListener('mousemove', (_event) =>
    {
      this.mouseX = _event.clientX
      this.mouseY = _event.clientY
        
    })
  }


  setModel()
  {
    
    this.loader = new GLTFLoader();

    
    let mixer
    this.loader.load('models/hands.glb',(gltf)=>{
      // this.scene.add(gltf.scene)
      this.hands = gltf.scene


      this.hands.scale.set(0.05, 0.05, 0.05)
      this.hands.position.set(0, - 0.5, 0)
      this.hands.rotation.set(- 0.5, 0, 0)

      // Animation


      this.mixer = new THREE.AnimationMixer(this.hands)
      this.clips = gltf.animations

      this.clip = THREE.AnimationClip.findByName(this.clips, 'ArmatureAction')
      this.action = this.mixer.clipAction(this.clip)
      this.action.play()
      

    })


  }

  settings() {
    let that = this;
    this.settings = {
      progress: 0.5,
      mRefractionRatio: 0.,
      mFresnelBias: 0.,
      mFresnelScale: 0.6,
      mFresnelPower: 0.97,
    };
    this.gui = new GUI();
    this.gui.add(this.settings, "progress", -1, 1, 0.01)
    this.gui.add(this.settings, "mRefractionRatio", 0, 3, 0.01).onChange(()=>{
      this.material2.uniforms.mRefractionRatio.value = this.settings.mRefractionRatio
    });
    this.gui.add(this.settings, "mFresnelBias", 0, 3, 0.01).onChange(()=>{
      this.material2.uniforms.mFresnelBias.value = this.settings.mFresnelBias
    });
    this.gui.add(this.settings, "mFresnelScale", 0, 3, 0.01).onChange(()=>{
      this.material2.uniforms.mFresnelScale.value = this.settings.mFresnelScale
    });
    this.gui.add(this.settings, "mFresnelPower", 0, 3, 0.01).onChange(()=>{
      this.material2.uniforms.mFresnelPower.value = this.settings.mFresnelPower
    });
    this.gui.hide()
  
  }

  initPost(){
    this.composer = new EffectComposer( this.renderer );
    this.composer.addPass( new RenderPass( this.scene, this.camera ) );

    const effect1 = new ShaderPass( DotScreenShader );
    effect1.uniforms[ 'scale' ].value = 4;
    this.composer.addPass( effect1 );



  }

  setupResize() {
    
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.composer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;

    

    // image cover
    this.imageAspect = 853/1280;
    let a1; let a2;
    if(this.height/this.width>this.imageAspect) {
      a1 = (this.width/this.height) * this.imageAspect ;
      a2 = 1;
    } else{
      a1 = 1;
      a2 = (this.height/this.width) / this.imageAspect;
    }

    this.camera.updateProjectionMatrix();


  }

  addObjects() {


    this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256,{
        format: THREE.RGBAFormat,
        generateMipmaps: true,
        minFilter: THREE.LinearMipMapLinearFilter,
        encoding: THREE.sRGBEncoding
      }
    )

    this.cubeCamera = new THREE.CubeCamera(0.7,30,this.cubeRenderTarget)

    let that = this;
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        progress: { value: 0 },
        resolution: { value: new THREE.Vector4() },
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });

    this.material5 = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        progress: { value: 0 },
        resolution: { value: new THREE.Vector4() },
      },
      // wireframe: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment
    });

    

    this.geometry = new THREE.SphereBufferGeometry(1.5, 32,32);

    this.plane = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.plane);
    

    // Center Sphere
    this.geometry2 = new THREE.SphereBufferGeometry(1.5, 32,32);

    this.plane2 = new THREE.Mesh(this.geometry2, this.material5);
    this.scene.add(this.plane2);

    this.plane2.material.opacity = 0.0

    this.plane2.scale.set(0.095, 0.095, 0.095)
    this.plane2.position.set(0, 0.19, 0)




    let geo = new THREE.SphereBufferGeometry(0.4,32,32);
    this.material2 = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        tCube: { value: 0 },
        mRefractionRatio: {value: 0.6},
        mFresnelBias: {value: 0.46},
        mFresnelScale: {value: 1.05},
        mFresnelPower: {value: 1.97},
        resolution: { value: new THREE.Vector4() },
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex1,
      fragmentShader: fragment1
    });

    this.material3 = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector4() },
        matcap: { value: new THREE.TextureLoader().load(matcap) },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex2,
      fragmentShader: fragment2
    });

    this.material4 = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector4() },
        matcap: { value: new THREE.TextureLoader().load(matcap2) },
        uvRate1: {
          value: new THREE.Vector2(1, 1)
        }
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex3,
      fragmentShader: fragment3
    });

    

    this.smallSphere = new THREE.Mesh(geo,this.material2)
    this.scene.add(this.smallSphere)
    
    this.smallSphere.position.set(0.0, 0.17, 0)
    

    // Planet 2

    this.smallSphere2 = new THREE.Mesh(geo,this.material4)
    // this.scene.add(this.smallSphere2)

    this.smallSphere2Obj = new THREE.Object3D()
    this.smallSphere2Obj.add(this.smallSphere2)

    this.scene.add(this.smallSphere2Obj)
    // this.smallSphere.add(this.smallSphere2)

    this.smallSphere2.scale.set(0.1, 0.1, 0.1)
    this.smallSphere2.position.set(0., 0.1, 0.5)

    
    // Planet 3

    this.smallSphere3 = new THREE.Mesh(geo,this.material3)
    // this.scene.add(this.smallSphere2)

    this.smallSphere3Obj = new THREE.Object3D()
    this.smallSphere3Obj.add(this.smallSphere3)

    this.scene.add(this.smallSphere3Obj)
    // this.smallSphere.add(this.smallSphere2)

    this.smallSphere3.scale.set(0.23, 0.23, 0.23)
    this.smallSphere3.position.set(0., 0., 0.6)

    // // Particles

    this.particleTextureLoader = new THREE.TextureLoader()
    this.cross = this.particleTextureLoader.load('./textures/cross.png')

    this.particlesGeometry = new THREE.BufferGeometry

    this.pointsMaterial = new THREE.PointsMaterial({
      size: 0.002,
      map: this.cross,
      transparent: true,
      color: 'white',
      blending: THREE.AdditiveBlending
    })

    this.particlesCnt = 4300

    this.posArray = new Float32Array(this.particlesCnt * 3)

    for(let i = 0; i < this.particlesCnt * 3; i++) {
      // this.posArray[i] = Math.random()
      // this.posArray[i] = Math.random() - 0.5
      this.posArray[i] = (Math.random() - 0.5) * 5
    }

    this.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(this.posArray, 3))

    this.particlesMesh = new THREE.Points(this.particlesGeometry, this.pointsMaterial)

    this.scene.add(this.particlesMesh)


    ///////////////////
    // SECOND SCENE //
    //////////////////

    // const canvas2 = document.querySelector('#c');
    // this.renderer2 = new THREE.WebGLRenderer({ canvas2, alpha: true});

    // const sceneElements = [];
    // // function addScene(elem, fn) {
    // //   sceneElements.push({elem, fn});
    // // }

    // this.scene2 = new THREE.Scene();
    // this.scene2.background = new THREE.Color('red')

    // const fov = 45;
    // const aspect = 2;  // the canvas default
    // const near = 0.1;
    // const far = 5;
    // this.camera2 = new THREE.PerspectiveCamera(fov, aspect, near, far);
    // this.camera2.position.set(0, 1, 2);
    // this.camera2.lookAt(0, 0, 0);

    // const color = 0xFFFFFF;
    // const intensity = 1;
    // const light = new THREE.DirectionalLight(color, intensity);
    // light.position.set(-1, 2, 4);
    // this.scene2.add(light);

    // const elem = document.querySelector('#pyramid');
    // // const {scene, camera} = makeScene();
    // this.scene2_radius = .8;
    // this.scene2_widthSegments = 4;
    // this.scene2_heightSegments = 2;
    // this.scene2_geometry = new THREE.SphereBufferGeometry(this.scene2_radius, this.scene2_widthSegments, this.scene2_heightSegments);
    // this.scene2_material = new THREE.MeshPhongMaterial({
    //   color: 'blue',
    //   flatShading: true,
    // });
    // this.scene2_mesh = new THREE.Mesh(this.scene2_geometry, this.scene2_material);
    // this.scene2.add(this.scene2_mesh);
    // this.scene2_mesh.scale.set(10, 10, 10)


    // this.camera2.aspect = this.width / this.height;
    // this.camera2.updateProjectionMatrix();
    // this.renderer2.render(this.scene2, this.camera2);


}

  



  stop() {
    this.isPlaying = false;
  }

  play() {
    if(!this.isPlaying){
      this.isPlaying = true;
      this.render()
    }
  }

  setClock()
  {
      this.clock = new THREE.Clock()
  }

  render() {
    if (!this.isPlaying) return;
    this.time += 0.01;
    this.material.uniforms.progress.value = this.settings.progress
    this.smallSphere.visible = false;
    this.mixer = this.time
    this.cubeCamera.update(this.renderer,this.scene);
    this.smallSphere.visible = true;
    this.smallSphere.position.y =  0.15 + Math.sin(this.time) * 0.025
    this.smallSphere.rotateY(0.004)
    this.smallSphere2.rotateY(0.004)
    this.smallSphere2Obj.rotateY(0.04)
    this.smallSphere3Obj.rotateY(0.02)

    // Update Particles

    this.particlesMesh.rotation.x = -.1 * this.time
    

    if(this.mouseX > 0) {
      this.particlesMesh.rotation.x = -this.mouseX * (this.time * 0.00003)
      this.particlesMesh.rotation.y = -this.mouseY * (this.time * 0.00003)
    }

    this.camera.position.x = this.mouseX * 0.000001

    // SCENE2 //
    // this.scene2_mesh.rotation.y = this.time * .1;
    // this.renderer2.render(this.scene2, this.camera2);


    this.material2.uniforms.tCube.value = this.cubeRenderTarget.texture
    this.material.uniforms.time.value = this.time;
    requestAnimationFrame(this.render.bind(this));

    this.composer.render(this.scene, this.camera);
  }

}

// Overlay added Models and Scenes

function main() {
  const canvas = document.querySelector('#c');
  const renderer = new THREE.WebGLRenderer({canvas, alpha: true});
  renderer.setClearColor( 0xffffff, 0);

  function makeScene(elem) {
    const scene = new THREE.Scene();

    const fov = 45;
    const aspect = 2;  // the canvas default
    const near = 0.1;
    const far = 5;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.set(0, 1, 2);
    camera.lookAt(0, 0, 0);

    {
      const color = 0xFFFFFF;
      const intensity = 1;
      const light = new THREE.DirectionalLight(color, intensity);
      light.position.set(-1, 2, 4);
      scene.add(light);
    }

    return {scene, camera, elem};
  }

  // function setupScene1() {
  //   const sceneInfo = makeScene(document.querySelector('#box'));
  //   const geometry = new THREE.BoxGeometry(1, 1, 1);
  //   const material = new THREE.MeshPhongMaterial({color: '#ed9433'});
  //   const mesh = new THREE.Mesh(geometry, material);
  //   sceneInfo.scene.add(mesh);
  //   sceneInfo.mesh = mesh;
  //   return sceneInfo;
  // }

  function setupScene2() {



    const sceneInfo = makeScene(document.querySelector('#pyramid'));
    const radius = .8;
    const widthSegments = 4;
    const heightSegments = 2;
    const geometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments);
    const material = new THREE.MeshPhongMaterial({
      color: '#8d8d8d7e',
      flatShading: true,
      wireframe: false,
    });


    ////////////////////////
    ///// GLTF LOADER /////
    ///////////////////////


    // Instantiate a loader
    const loader = new GLTFLoader();


    // Load a glTF resource
    loader.load(
      // resource URL
      'models/heart.glb',
      // called when the resource is loaded
      function ( gltf ) {

        sceneInfo.scene.add( gltf.scene );
        gltf.scene.scale.set(0.4, 0.4, 0.4)
        gltf.scene.position.set(0,-0.8,0)
        gltf.scene.rotation.set(0,-0.7,0)
        gltf.scene.children[0].material.opacity = 0

        console.log(gltf.scene.children[0].material)

        gsap.to(gltf.scene.children[0].material, { delay: 1, z: 0.0, opacity:1, y: 0.35, duration: 7, scrollTrigger: {trigger: '.section2', scrub: 1.5}, }, "-=0.0")


        function render(time) {
          time *= 0.001;
      
          gltf.scene.rotation.y = time * .3;
      
      
          requestAnimationFrame(render);
        }
        requestAnimationFrame(render);
      },

    );

    /////////////////////////
    /////////////////////////



    const mesh = new THREE.Mesh(geometry, material);
    // sceneInfo.scene.add(mesh);
    sceneInfo.mesh = mesh;
    return sceneInfo;
  }

  // const sceneInfo1 = setupScene1();
  const sceneInfo2 = setupScene2();

  function resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  function renderSceneInfo(sceneInfo) {
    const {scene, camera, elem} = sceneInfo;

    // get the viewport relative position of this element
    const {left, right, top, bottom, width, height} =
        elem.getBoundingClientRect();

    const isOffscreen =
        bottom < 0 ||
        top > renderer.domElement.clientHeight ||
        right < 0 ||
        left > renderer.domElement.clientWidth;

    if (isOffscreen) {
      return;
    }

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    const positiveYUpBottom = renderer.domElement.clientHeight - bottom;
    renderer.setScissor(left, positiveYUpBottom, width, height);
    renderer.setViewport(left, positiveYUpBottom, width, height);

    renderer.render(scene, camera);
  }

  function render(time) {
    time *= 0.001;

    resizeRendererToDisplaySize(renderer);

    renderer.setScissorTest(false);
    renderer.clear(true, true);
    renderer.setScissorTest(true);

    // sceneInfo1.mesh.rotation.y = time * .1;
    sceneInfo2.mesh.rotation.y = time * .1;

    // renderSceneInfo(sceneInfo1);
    renderSceneInfo(sceneInfo2);

    requestAnimationFrame(render);
  }

  requestAnimationFrame(render);
}

main();




