// import './style.css'
import Sketch from './module'
import mixitup from './lib/mixitup.min.js'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import * as THREE from 'three'





///---GSAP ANIMATIONS---///

gsap.registerPlugin(ScrollTrigger)
let tl = gsap.timeline()


// let titles = [...document.querySelectorAll('.section2 h2')]
// console.log(titles)



let sketch = new Sketch({
    dom: document.getElementById("container")
})


gsap.to('#logo', { delay: 0, opacity: 1.0, y: -5, duration: 2 }, "-=0.0")

gsap.to('#onButton', { delay: 0, opacity: 1, y: -2, duration: 2 }, "+=5.0")


// Home Section ScrollTrigger

gsap.utils.toArray('.wrap').forEach(section => {
  const home = section.querySelectorAll('.section1');
  // Set starting params for sections
  gsap.set(home, {
    y: - 30,
    opacity: 1,
    duration: 1,
    ease: 'power3.out',
    overwrite: 'auto',
  });
  
  ScrollTrigger.create({
    trigger: '.section2',
    start: 'top 95%',
    end: 'bottom 30%',
    // markers: true,
    onEnter: () => gsap.to(home, {
      y: -50,
      opacity: 0,
      stagger: 0.2,
      duration: 2
    }),
    onLeave: () => gsap.to(home, {
      y: 50,
      opacity: 0,
      stagger: 0.2,
      duration: 2
    }),
    onEnterBack: () => gsap.to(home, {
      y: -50,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
    onLeaveBack: () => gsap.to(home, {
      y: - 30,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
  });
})

// Black Bar Top ScrollTrigger

gsap.utils.toArray('.wrap').forEach(section => {
  const barTop = section.querySelectorAll('.blackBarTop');
  // Set starting params for sections
  gsap.set(barTop, {
    y: -65,
    opacity: 1,
    duration: 3,
    ease: 'power3.out',
    overwrite: 'auto',
  });
  
  ScrollTrigger.create({
    trigger: '.section2',
    start: 'top 95%',
    end: 'bottom 30%',
    // markers: true,
    onEnter: () => gsap.to(barTop, {
      y: 0,
      opacity: 1,
      stagger: 0.2,
      duration: 2
    }),
    onLeave: () => gsap.to(barTop, {
      y: -65,
      opacity: 1,
      stagger: 0.2,
      duration: 2
    }),
    onEnterBack: () => gsap.to(barTop, {
      y: 0,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
    onLeaveBack: () => gsap.to(barTop, {
      y: -65,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
  });
})

// Black Bar Bottom ScrollTrigger

gsap.utils.toArray('.wrap').forEach(section => {
  const barBottom = section.querySelectorAll('.blackBarBottom');
  // Set starting params for sections
  gsap.set(barBottom, {
    y: 65,
    opacity: 1,
    duration: 3,
    ease: 'power3.out',
    overwrite: 'auto',
  });
  
  ScrollTrigger.create({
    trigger: '.section2',
    start: 'top 95%',
    end: 'bottom 30%',
    // markers: true,
    onEnter: () => gsap.to(barBottom, {
      y: 0,
      opacity: 1,
      stagger: 0.2,
      duration: 2
    }),
    onLeave: () => gsap.to(barBottom, {
      y: 65,
      opacity: 1,
      stagger: 0.2,
      duration: 2
    }),
    onEnterBack: () => gsap.to(barBottom, {
      y: 0,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
    onLeaveBack: () => gsap.to(barBottom, {
      y: 65,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
  });
})

// About Section ScrollTrigger

gsap.utils.toArray('.wrap').forEach(section => {
  const about = section.querySelectorAll('.section2');
  // Set starting params for sections
  gsap.set(about, {
    y: 50,
    opacity: 0,
    duration: 1,
    ease: 'power3.out',
    overwrite: 'auto',
  });
  
  ScrollTrigger.create({
    trigger: '.section2',
    start: 'top 27%',
    end: 'bottom 20%',
    // markers: true,
    onEnter: () => gsap.to(about, {
      y: 0,
      opacity: 1,
      stagger: 0.2,
      duration: 2
    }),
    onLeave: () => gsap.to(about, {
      y: -50,
      opacity: 0,
      stagger: 0.2,
      duration: 2
    }),
    onEnterBack: () => gsap.to(about, {
      y: 0,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
    onLeaveBack: () => gsap.to(about, {
      y: 50,
      opacity: 0,
      stagger: -0.2,
      duration: 2
    }),
  });
})

// About section "Title" trigger

gsap.utils.toArray('.wrap').forEach(section => {
  const about = section.querySelectorAll('.sec2-title');
  // Set starting params for sections
  gsap.set(about, {
    y: 50,
    opacity: 0,
    duration: 1,
    ease: 'power3.out',
    overwrite: 'auto'
  });
  
  ScrollTrigger.create({
    trigger: '.section2',
    start: 'top 27%',
    end: 'bottom 20%',
    // markers: true,
    onEnter: () => gsap.to(about, {
      y: 0,
      opacity: 1,
      stagger: 0.2,
      duration: 2,
      delay: 0.3,
    }),
    onLeave: () => gsap.to(about, {
      y: -50,
      opacity: 0,
      stagger: 0.2,
      duration: 2,
      delay: 0.6,
    }),
    onEnterBack: () => gsap.to(about, {
      y: 0,
      opacity: 1,
      stagger: -0.2,
      duration: 2,
      delay: 0.3,
    }),
    onLeaveBack: () => gsap.to(about, {
      y: 50,
      opacity: 0,
      stagger: -0.2,
      duration: 2,
      delay: 0.6,
    }),
  });
})

// About section "Paragraph" trigger

gsap.utils.toArray('.wrap').forEach(section => {
  const about = section.querySelectorAll('.sec2-para');
  // Set starting params for sections
  gsap.set(about, {
    y: 45,
    opacity: 0,
    duration: 1,
    ease: 'power3.out',
    overwrite: 'auto'
  });
  
  ScrollTrigger.create({
    trigger: '.section2',
    start: 'top 27%',
    end: 'bottom 20%',
    // markers: true,
    onEnter: () => gsap.to(about, {
      y: 0,
      opacity: 1,
      stagger: 0.2,
      duration: 2,
      delay: 2,
      delay: 0.7
    }),
    onLeave: () => gsap.to(about, {
      y: -50,
      opacity: 0,
      stagger: 0.2,
      duration: 2,
      delay: 0.3
    }),
    onEnterBack: () => gsap.to(about, {
      y: 0,
      opacity: 1,
      stagger: -0.2,
      duration: 2,
      delay: 0.7
    }),
    onLeaveBack: () => gsap.to(about, {
      y: 50,
      opacity: 0,
      stagger: -0.2,
      duration: 2,
      delay: 0.3
    }),
  });
})

// Work Section ScrollTrigger

gsap.utils.toArray('.wrap').forEach(section => {
  const work = section.querySelectorAll('.work-section');
  // Set starting params for sections
  gsap.set(work, {
    y: 50,
    opacity: 0,
    duration: 1,
    ease: 'power3.out',
    overwrite: 'auto',
  });
  
  ScrollTrigger.create({
    trigger: '.work-section',
    start: 'top 67%',
    end: 'bottom 20%',
    // markers: true,
    onEnter: () => gsap.to(work, {
      y: 0,
      opacity: 1,
      stagger: 0.2,
      duration: 2
    }),
    onLeave: () => gsap.to(work, {
      y: -50,
      opacity: 0,
      stagger: 0.2,
      duration: 2
    }),
    onEnterBack: () => gsap.to(work, {
      y: 0,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
    onLeaveBack: () => gsap.to(work, {
      y: 50,
      opacity: 0,
      stagger: -0.2,
      duration: 2
    }),
  });
})

// Contact Section ScrollTrigger

gsap.utils.toArray('.wrap').forEach(section => {
  const contact1 = section.querySelectorAll('.section3');
  // Set starting params for sections
  gsap.set(contact1, {
    y: 50,
    opacity: 0,
    duration: 1,
    ease: 'power3.out',
    overwrite: 'auto',
  });
  
  ScrollTrigger.create({
    trigger: '.section3',
    start: 'top 27%',
    end: 'bottom 20%',
    // markers: true,
    onEnter: () => gsap.to(contact1, {
      y: 0,
      opacity: 1,
      stagger: 0.2,
      duration: 2
    }),
    onLeave: () => gsap.to(contact1, {
      y: -50,
      opacity: 0,
      stagger: 0.2,
      duration: 2
    }),
    onEnterBack: () => gsap.to(contact1, {
      y: 0,
      opacity: 1,
      stagger: -0.2,
      duration: 2
    }),
    onLeaveBack: () => gsap.to(contact1, {
      y: 50,
      opacity: 0,
      stagger: -0.2,
      duration: 2
    }),
  });
})















// gsap.to('#logo', { delay: 0, opacity: 0.0, duration: 2, scrollTrigger: {trigger: '.section2 h2', scrub: true}, }, "-=0.0")


gsap.to(sketch.camera.position, { delay: 0, z: 0.0, y: 0.35, duration: 7, scrollTrigger: {trigger: '.section2', scrub: 1.5}, }, "-=0.0")


// gsap.to(sketch.camera.position, { delay: 0, z: 0, y: 0.05, duration: 1, scrollTrigger: {trigger: '.work-section', scrub: 1.5}, }, "-=0.0")
// gsap.to(sketch.material5.opacity, { delay: 0, value: 1, duration: 3, scrollTrigger: {trigger: '.section3 h2', scrub: 1.5}, }, "-=0.0")

gsap.to(sketch.settings, {delay: 0, progress: -1, duration: 6, scrollTrigger: { trigger: '.section2 h2', scrub: 1.5}, }, "-=0.0");












 // MIXITUP FILTER PORTFOLIO

 let mixerPortfolio = mixitup('.work__container', {
  selectors: {
      target: '.work__card'
  },
  animation: {
      duration: 300
  }
});

const linkWork = document.querySelectorAll('.work__item')

function activeWork(){
  linkWork.forEach(l=> l.classList.remove('active-work'))
  this.classList.add('active-work')
}

linkWork.forEach(l=> l.addEventListener('click', activeWork))



// WORK POP UP DISPLAY

// Work Card 1 (copy and paste code below to add new section)

const workPopup_1 = document.querySelectorAll('.workcard_1_popup'),
    workBtn_1 = document.querySelectorAll('.workcard_1_btn'),
    modalClose = document.querySelectorAll('.services__modal-close')

let modal = function(modalClick) {
  workPopup_1[modalClick].classList.add('active-modal')
}

workBtn_1.forEach((mb, i) =>{
  mb.addEventListener('click', () =>{
      modal(i)
  })
})

modalClose.forEach((mc) =>{
  mc.addEventListener('click', () =>{
    workPopup_1.forEach((mv) =>{
          mv.classList.remove('active-modal')
      })
  })
})


